import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import focus from '@alpinejs/focus';
import swipePlugin from 'alpinejs-swipe';

document.addEventListener('alpine:init', () => {
    Alpine.magic('fetch', () => {
        return async (url, options = {}) => {
            try {
                const response = await jsonFetch(url, options);
                return options.jsonItem ? response[options.jsonItem] : response;
            } catch (error) {
                throw error;
            }
        };
    });
});

async function jsonFetch(
    url,
    { jsonItem = null, method = 'GET', headers = {}, ...options } = {}
) {
    try {
        const response = await fetch(url, { method, headers, ...options });

        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            const data = await response.json();
            return jsonItem ? data[jsonItem] : data;
        } else {
            return response.text();
        }
    } catch (error) {
        throw error;
    }
}

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(intersect);
Alpine.plugin(focus);
